<template>
  <div class="WebCastRecent content-margin">
    <h2>{{ data.title }}</h2>
    <div class="WebCastRecent__content" data-aos="fade-up">
      <div
        v-for="(slide, i) in slides"
        class="WebCastRecent__content-card"
        :data-textId="`textId-${slides[i].idTexts}`"
        @click="openYoutubeModal($event, { videoId: slides[i].media, text: slides[i].texts })"
        :key="i"
      >
        <button class="button-play">
          <iconic name="btn_play" />
        </button>
        <img class="background-image" :src="urlCloudFront($screen.width > 430 ? slides[i].poster.desktop : slides[i].poster.mobile)" alt="poster" />
        <div class="WebCastRecent__content-card-footer" :class="{ expanded: showFullText }">
          <div class="WebCastRecent__content-card-text" :class="{ 'slide__text-full-container': showFullText }" @click="toggleText">
            <h4 class="WebCastRecent__content-text-title">{{ slides[i].texts.title }}</h4>
          </div>
        </div>
      </div>
    </div>

    <postModal ref="postModal" />
    <shareModal ref="shareModal" />
  </div>
</template>
<script>
import global from "@/assets/js/global.js";
import VueSlickCarouselc from "vue-slick-carousel";
import SignupButton from "./SignupButton.vue";
import postModal from "../modals/PostModal.vue";
import shareModal from "../modals/sharePostModal.vue";

export default {
  components: { VueSlickCarouselc, SignupButton, postModal, shareModal },
  props: ["data"],
  data: function () {
    return {
      showFullText: false,
      maxCharactersToShow: 150,
      cardsToShow: 6,
      showMoreActive: false,
      showModal: true,
    };
  },
  computed: {
    slides: function () {
      return this.data.multimedia.map((media) => {
        const { text, title, subtitle = "" } = media.texts;
        return {
          type: ["youtube", "video"].includes(media.platformType) ? "video" : "image",
          media: media.url.desktop,
          poster: media.poster,
          texts: { text, title, subtitle },
          idTexts: media.texts._id,
        };
      });
    },
  },

  methods: {
    /**
     * Funcion encargada de mostrar cierta parte de un texto mandado por con parametros
     * @param {*} text
     * @param {*} limit
     */
    getShort: (text, limit) => {
      return global.getShort(text, limit);
    },
    showMoreImages() {
      this.cardsToShow += this.showMoreActive ? -3 : 3;
      this.showMoreActive = !this.showMoreActive;
    },
    urlCloudFront(link) {
      return this.$global.newUrl(link);
    },
    openShareModal() {
      this.$refs.shareModal.openShareModal();
    },
    /**
     * Funcion que trabaja como handler para llamar la funcion openModal que esta en el componente de postModal
     * @param {*} evt
     */
    openYoutubeModal(evt, { videoId, text }) {
      const isTextClicked = evt.target.classList.contains("slide__text-short");
      if (isTextClicked && this.$screen.width < 768) {
        return;
      }
      this.$refs.postModal.openYoutubeModal({ url: videoId, text }, true);
    },

    /**
     * Funcion encargada de remover las entidades html que vienen desde base de datos
     * @param {*} textoHTML
     */
    removeHTMLEntities: function (textoHTML) {
      return global.removeHTMLEntities(textoHTML);
    },

    /**
     * Funcion encargada de buscar el elemento padre de un elemento mandado por parametro
     * @param {*} evt
     */
    foundParentElement(evt) {
      let parentElementDiv = evt.target;
      let isSlideElement = evt.target.classList.contains("WebCastRecent__content-card");
      while (!isSlideElement) {
        parentElementDiv = parentElementDiv.parentElement;
        isSlideElement = parentElementDiv.classList.contains("WebCastRecent__content-card");
      }
      return parentElementDiv;
    },
    manageVideoPlay(evt, index) {
      let parentElementDiv = this.foundParentElement(evt);
      const video = parentElementDiv.querySelector("video");
      const isMuted = this.slides["mobile"][index].muted;

      video.muted = !isMuted;
      this.slides["mobile"][index].muted = !isMuted;
    },
    insertComponent(evt) {
      let parentElementDiv = this.foundParentElement(evt);
      parentElementDiv.querySelector("video").play();
    },
    removeComponent(evt) {
      let parentElementDiv = this.foundParentElement(evt);
      parentElementDiv.querySelector("video").pause();
      parentElementDiv.querySelector("video").currentTime = 0;
    },
    toggleText() {
      if (this.$screen.width <= 768) {
        this.showFullText = !this.showFullText;
        const textShort = document.querySelectorAll(".slide__text-short");
        document.querySelectorAll(".slick-arrow").forEach((arrow) => arrow.classList.toggle("hide"));
        if (this.showFullText) {
          textShort.forEach((arrow) => arrow.classList.toggle("hide-text"));
        } else {
          textShort.forEach((arrow) => arrow.classList.toggle("hide-text"));
        }
      }
    },
  },
};
</script>

<style lang="scss">
.WebCastRecent {
  .button-signin {
    font-size: 15px;
    background: $primary_color;
    padding: 7px 15px;
    border-radius: 20px;
    text-transform: uppercase;
    transition: 0.3s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }
  h2 {
    color: $primary_color;
    text-align: center;
    margin-bottom: 20px;
  }
  &__title {
    padding: 20px;
    color: #fff;
    background: $primary_color;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
  }
  &__content {
    margin: 35px 10px;
    padding-bottom: 22px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    button {
      color: #fff;
      background: transparent;
    }

    &-card {
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      border-radius: 12px;
      max-height: 500px;
      box-shadow: 0px 1px 7px 0px #000000d9;
      height: fit-content;
      width: fit-content;
      video {
        height: 100%;
        position: absolute;
      }
      .button-play {
        display: none;
      }
      .background-image {
        height: 100%;
        width: 100%;
        border-radius: 12px;
        object-fit: contain;
      }
      &-footer {
        position: absolute;
        z-index: 40;
        bottom: 0;
        color: #fff;
        width: 100%;
        max-height: 100px;
        .WebCastRecent__content {
          &-text-title {
            text-transform: uppercase;
            z-index: 1;
          }
        }
      }
      video.video-blur {
        transition: filter 0.3s ease-in-out;
        filter: blur(3px);
      }

      &-text {
        cursor: pointer;
        height: 100%;
        overflow: hidden;
        width: 100%;
        z-index: 20;
        padding: 10px 20px;
        font-size: 16px;
        transition: max-height 0.4s ease-in-out;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 68%);
        .slide__text-short {
          display: none;
        }
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 6.5%, rgba(0, 0, 0, 0.77) 91.15%);
        }
      }
      &-buttons {
        display: flex;
        justify-content: space-between;
        padding: 20px;
      }
      &-reactions {
        display: flex;
        gap: 19px;
        .button-reaction {
          display: flex;
          gap: 5px;
          align-items: flex-end;
          font-size: 23px;
          span {
            font-weight: 200;
            font-size: 18px;
          }
        }
      }
    }
  }
  &__showMore {
    display: grid;
    place-items: center;
    button {
      z-index: 2;
      width: 160px;
      height: 40px;
      border-radius: 20px;
      background: $primary_color;
      color: #fff;
      text-transform: uppercase;
      transition: transform 0.4s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      font-family: Montserrat, sans-serif;
      font-weight: 400;
      .iconic {
        font-size: 15px;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  @include MobileXSmall {
    &__content {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 15px;
    }
  }
  @include MobileSmall {
    &__content {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
      &-card {
        border-radius: 12px;
        &-footer {
          .WebCastRecent__content {
            &-text-title {
              text-transform: uppercase;
              z-index: 1;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 20ch;
            }
          }
        }
        &-text {
          cursor: pointer;
          max-height: 115px;
        }
      }
    }
  }
  @include Mobile {
    &__content {
      &-card {
        &-footer {
          .WebCastRecent__content {
            &-text-title {
              text-transform: uppercase;
              z-index: 1;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 60ch;
            }
          }
        }
      }
    }
  }
  @include MobileMiddle {
    &__content {
      display: grid;
    }
  }
  @include Tablet {
    &__content {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      margin-bottom: 100px;

      &-card {
        border-radius: 12px;
        &-footer {
          .WebCastRecent__content {
            &-text-title {
              text-transform: uppercase;
              z-index: 1;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 54ch;
            }
          }
        }
      }

      .background-image {
        height: 100%;
        max-width: 100%;
        border-radius: 12px;
      }
      .slick-track {
        display: flex;
        justify-content: space-between;
        gap: 10px;
      }
    }
  }
  @include Desktop {
    h2 {
      text-align: left;
      padding-left: 0;
      margin-bottom: 10px;
      margin-top: 60px;
    }

    &__content {
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 50px;
      video {
        width: fit-content;
      }

      &-card {
        position: relative;
        overflow: hidden;
        display: flex !important;
        align-items: center;
        justify-content: center;

        .button-play {
          display: block;
          visibility: hidden;
          position: absolute;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 50%;
          height: 70px;
          width: 70px;
          top: 0;
          left: 50%;
          top: 50%;
          z-index: 10;
          transform: translate(-50%, -50%);
          .iconic {
            font-size: 30px;
          }
        }
        &:hover > .button-play {
          visibility: visible;
          transition: visibility 0.3s ease-in;
        }
        &-buttons {
          display: none;
        }
        &-text {
          font-size: 23px;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0px;
            width: 100%;
            height: 100%;
            backdrop-filter: none;
          }
          h4,
          p {
            z-index: 2;
          }
          .slide__text-full {
            display: none;
          }
          .slide__text-short {
            display: flex;
          }
        }
      }
    }
  }
}
</style>